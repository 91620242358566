<template>
    <BT-Blade-Item
        :bladesData="bladesData"
        bladeName="journey-template"
        :dependantBlades="['journey-pointer']"
        navigation="journey-templates"
        title="Journey Template"
        @sourceChanged="updateBlade"
        :actualUsedHeight="112">
        <template slot-scope="{ item, data }">
            <BT-Field-String
                v-model="item.journeyName"
                label="Journey Name"
                :isEditing="data.isEditing || data.isNew" />
            
            <BT-Field-Select
                navigation="users"
                v-model="item.driverUserID"
                itemText="userName"
                label="Driver"
                :isEditing="data.isEditing || data.isNew" />

            <BT-Field-Select
                navigation="locations"
                v-model="item.startLocationID"
                itemText="locationName"
                label="Start Location"
                :isEditing="data.isEditing || data.isNew" />

            <BT-Field-Select
                navigation="locations"
                v-model="item.endLocationID"
                itemText="locationName"
                label="End Location"
                :isEditing="data.isEditing || data.isNew" />
            
            <BT-Field-Number
                v-model.number="item.maxDestinations"
                label="Destination Count Capacity"
                :isEditing="data.isEditing || data.isNew" />
            
            <BT-Field-Number
                v-model.number="item.maxWeight"
                label="Weight Capacity"
                :isEditing="data.isEditing || data.isNew" />

            <BT-Field-Number
                v-model.number="item.maxPackages"
                label="Package Count Capacity"
                :isEditing="data.isEditing || data.isNew" />

            <v-divider />

            <BT-Select-Date-Dialog
                v-if="!data.isNew"
                :hideDetails="true"
                @ok="d => { createJourney(d, item) }"
                :block="true"
                buttonClass="primary"
                small
                text="Create Journey" />
            
            <!-- <BT-Blade-Button
                v-if="!data.isNew && bladesData != null"
                @click="data.openBlade({ bladeName: 'journey-pointer', data: { id: 'new', templateID: templateID } })"
                label="Create Coverage" /> -->

            <BT-Snack v-model="msg" />

            <v-divider v-if="!data.isNew" />

            <v-expansion-panels v-if="!data.isNew" v-model="panelV">
                <BT-Blade-Expansion-Items
                    addBladeName="journey-pointer"
                    :bladesData="bladesData"
                    canAdd
                    :canExportCSV="false"
                    :getNewBladeData="(bladeData) => { return { templateID: item.id } }"
                    :getNewQuery="(bladeData) => { return { templateID: item.id } }"
                    navigation="journey-pointers"
                    :refreshToggle="refreshToggle"
                    title="Journey Coverage"
                    :params="pointerParams"
                    showList
                    :showSettings="false">
                    <template v-slot:listItem="{ item }">
                        <v-list-item-icon>
                            <v-icon>mdi-map-search</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-subtitle>{{ item.weekdays }}</v-list-item-subtitle>
                        </v-list-item-content>
                    </template>
                </BT-Blade-Expansion-Items>
            </v-expansion-panels>
        </template>
    </BT-Blade-Item>
</template>

<script>
export default {
    name: 'Journey-Template-Blade',
    components: {
        BTSelectDateDialog: () => import('~components/BT-Select-Date-Dialog.vue')
    },
    data: function() {
        return {
            isLoading: false,
            msg: null,
            panelV: 0,
            refreshToggle: false,
            templateID: null
        }
    },
    props: {
        bladesData: {
            type: Object,
            default: null
        },
    },
    computed: {
        pointerParams() {
            return {
                includeDetails: false,
                journeyTemplateIDs: this.templateID
            }
        }
    },
    methods: {
        async createJourney(date, template) {
            this.isLoading = true;

            var post = {
                journeyName: template.journeyName,
                journeyTemplateID: template.id,
                dueStartOn: date,
                dueEndOn: date,
                driverUserID: template.driverUserID,
                maxDestinations: template.maxDestinations,
                maxWeight: template.maxWeight,
                maxPackages: template.maxPackages,
                startLocationID: template.startLocationID,
                endLocationID: template.endLocationID
            };

            try {
                await this.$BlitzIt.store.post('journeys', post);
            }
            catch (err) {
                this.msg = this.extractErrorDescription(err);
            }
            finally {
                this.msg = 'Journey Created';
                this.isLoading = false;
            }
        },
        createPointer(templateID) {
            this.$emit('openBlade', { name: 'journey-pointer', id: 'new', templateID });
        },
        openPointer(id, templateID) {
            this.$emit('openBlade', { name: 'journey-pointer', id: id, templateID });
        },
        async readyJourney(journey) {
            this.isLoading = true;

            try {
                var patch = Object.assign({}, journey, { isReadyRequest: true });
                var res = await this.$BlitzIt.store.patch('journeys', patch);
                journey.isReady = res.isReady;
                journey.rowVersion = res.rowVersion;
            }
            catch (err) {
                this.msg = this.extractErrorDescription(err);
            }
            finally {
                this.isLoading = false;
            }
        },
        sortMovements(list) {
            return list;
            //return list.orderBy('product.sortNumber');
        },
        updateBlade(bladeData) {
            if (bladeData != null) {
                this.templateID = bladeData.data.id;
                this.refreshToggle = !this.refreshToggle;
            }
        }
    }
}
</script>